import React from "react";
import Slider from "react-slick";

import "./ImageSlider.scss";
const ImageSlider = (props) => {
  const settings = {
    dots: true,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    variableWidth: true,
  };

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {props.items.map((item, index) => {
          return (
            <div className="image-slider__slide">
              <img src={item.slider_image.fluid.src} alt="Slide 1" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
export default ImageSlider;
