import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { withPreview } from "gatsby-source-prismic";

import Layout from "../components/layout";
import SEO from "../components/seo";

import "./../components/CaseStudy.scss";

import CaseStudyHero from "../components/CaseStudyHero";
import CaseStudyContentFull from "../components/CaseStudyContentFull";
import CaseStudyContentSplit from "../components/CaseStudyContentSplit";
import VideoPlayer from "../components/VideoPlayer";
import ImageSlider from "../components/ImageSlider";
import BlockQuote from "../components/BlockQuote";
import Share from "../components/Share";
import Related from "../components/Related";
import LogoGridExternal from "./../components/LogoGridExternal";
import ClutchReviews from "./../components/ClutchReviews";
import { Waypoint } from "react-waypoint";

export const query = graphql`
  query($id: String!) {
    prismicCaseStudy(id: { eq: $id }) {
      data {
        project_name
        meta_description
        meta_title
        canonical {
          uid
          type
        }
        client_name {
          text
        }
        logo {
          alt
          fluid {
            src
          }
        }
        hero {
          alt
          fluid(maxWidth: 1800) {
            src
          }
        }
        hero_text_color
        title {
          text
          html
        }
        related_work {
          case_study {
            document {
              ... on PrismicCaseStudy {
                uid
                data {
                  title {
                    text
                  }
                  thumb {
                    alt
                    fluid {
                      src
                    }
                  }
                }
              }
            }
          }
        }
        related_work_title {
          text
        }
        tags {
          tag {
            document {
              ... on PrismicCaseStudyTags {
                uid
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicCaseStudyBodyTwoColumn {
            id
            items {
              image {
                url
              }
            }
            primary {
              theme
              background
              section_title {
                text
              }
              reverse_columns
              headline {
                text
              }
              content {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyContentBlock {
            id
            primary {
              theme
              background
              content_title {
                text
              }
              image {
                alt
                fluid(maxWidth: 1200) {
                  src
                }
              }
              headline {
                text
              }
              summary {
                text
                html
              }
              left_column {
                html
              }
              right_column {
                html
              }
            }
          }
          ... on PrismicCaseStudyBodyQuoteBlock {
            id
            primary {
              quote_company
              quote_person
              quote_text {
                text
              }
              quote_title {
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyVideoBlock {
            id
            primary {
              shareable
              video {
                embed_url
                html
                url
              }
              video_title {
                text
              }
            }
            items {
              video_thumbnail {
                alt
                fluid {
                  src
                }
              }
              sharable
              video {
                embed_url
                html
                url
              }
            }
          }
          ... on PrismicCaseStudyBodyImageSliderBlock {
            id
            primary {
              slider_title {
                text
              }
            }
            items {
              slider_image {
                alt
                fluid(maxWidth: 1200) {
                  src
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyVideoAnimation {
            id
            primary {
              video {
                embed_url
                html
                url
              }
            }
          }
          ... on PrismicCaseStudyBodyEmbedBlock {
            id
            primary {
              embed_code {
                text
              }
            }
          }
          ... on PrismicCaseStudyBodyCallToAction {
            id
            primary {
              button_text {
                text
              }
              button_link {
                type
                uid
                url
                target
              }
              button_alignment
            }
          }
          ... on PrismicCaseStudyBodyLogoGridWExternalLinks {
            id
            primary {
              grid_title {
                text
              }
            }
            items {
              link {
                url
                target
              }
              logo {
                alt
                fluid {
                  src
                }
              }
            }
          }
          ... on PrismicCaseStudyBodyClutchReviews {
            id
          }
        }
      }
    }
  }
`;

const SplitContent = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const theme = props.content.theme
    ? props.content.theme.toLowerCase()
    : "light";

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section theme-${theme} waypoint ${
          active ? "waypoint-active" : ""
        }`}
        style={{ backgroundColor: props.content.background }}
      >
        <h4>{props.content.section_title.text}</h4>
        <div className="container container-large">
          <CaseStudyContentSplit {...props} />
        </div>
      </div>
    </Waypoint>
  );
};
const FullContent = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const theme = props.content.theme.toLowerCase();
  const left = props.content.left_column && props.content.left_column.html;
  const right = props.content.right_column && props.content.right_column.html;

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section theme-${theme} waypoint ${
          active ? "waypoint-active" : ""
        }`}
        style={{ backgroundColor: props.content.background }}
      >
        <h4>{props.content.content_title.text}</h4>
        <div className="container container-medium">
          <CaseStudyContentFull theme={theme} left={left} right={right}>
            {props.content.image && props.content.image.fluid && (
              <div>
                <div className="case-study-content__media">
                  <img src={props.content.image.fluid.src} />
                </div>
                <div className="case-study-content__spacer medium"></div>
              </div>
            )}

            {theme === "light" && (
              <div className="case-study__rich-text">
                <h3 className="heading heading--l text--medium">
                  {props.content.headline.text}
                </h3>
                {props.content.summary.html &&
                  props.content.summary.text !== "" && (
                    <div>
                      <div className="case-study-content__spacer medium"></div>
                      <div
                        className="text text--l"
                        dangerouslySetInnerHTML={{
                          __html: props.content.summary.html,
                        }}
                      ></div>
                    </div>
                  )}
              </div>
            )}
            {theme === "dark" && (
              <div className="case-study__rich-text">
                <h3 className="heading heading--l text--medium">
                  {props.content.headline.text}
                </h3>
                {props.content.summary.html &&
                  props.content.summary.text !== "" && (
                    <div>
                      <div className="case-study-content__spacer medium"></div>
                      <div className="row">
                        <div className="col col-md-8 col-md-offset-2">
                          <div
                            className="text text--m"
                            dangerouslySetInnerHTML={{
                              __html: props.content.summary.html,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            )}
          </CaseStudyContentFull>
        </div>
      </div>
    </Waypoint>
  );
};
const QuoteSlider = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section size--small waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="container container-medium">
          <BlockQuote content={props.content} item={props.content} />
        </div>
      </div>
    </Waypoint>
  );
};
const VideoBlock = (props) => {
  const videos = props.items;

  const [videoIndex, setVideoIndex] = useState(0);
  const [currentVideo, setCurrentVideo] = useState(videos[0]);

  const [active, setActive] = React.useState(false);
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    setCurrentVideo(videos[videoIndex]);
  }, [videoIndex]);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  const videoReady = () => {
    setReady(true);
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section color-gray waypoint ${
          active && ready ? "waypoint-active" : ""
        }`}
      >
        <h4>{props.content.video_title.text}</h4>
        <div className="container">
          <VideoPlayer
            videoReady={videoReady}
            videoURL={currentVideo.video.embed_url}
          />
          {currentVideo.sharable && (
            <Share shareURL={currentVideo.video.embed_url} minimal={true} />
          )}
        </div>
        {videos.length > 1 && (
          <div className="video-list">
            {videos.map((video, index) => {
              return (
                <div
                  className="video-item"
                  onClick={() => {
                    setVideoIndex(index);
                  }}
                >
                  <img src={video.video_thumbnail.fluid.src} alt="Video" />
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Waypoint>
  );
};

const VideoAnimation = ({ video }) => {
  const [active, setActive] = React.useState(false);
  const [ready, setReady] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  const videoReady = () => {
    setReady(true);
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section color-gray waypoint ${
          active && ready ? "waypoint-active" : ""
        }`}
      >
        <div className="container">
          <VideoPlayer
            autoPlay={true}
            videoReady={videoReady}
            videoURL={video.embed_url}
          />
        </div>
      </div>
    </Waypoint>
  );
};

const Slider = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };
  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section full-width waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <h4>{props.content.slider_title.text}</h4>
        <div className="">
          <ImageSlider items={props.items} />
        </div>
      </div>
    </Waypoint>
  );
};
const EmbedCode = (props) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`case-study__section color-gray waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <div className="container case-study-content">
          <div
            className="embedCode"
            dangerouslySetInnerHTML={{ __html: props.content.embed_code.text }}
          ></div>
        </div>
      </div>
    </Waypoint>
  );
};

const BlogCTA = ({ item }) => {
  const [active, setActive] = React.useState(false);

  const onEnter = () => {
    if (!active) {
      setActive(true);
    }
  };

  return (
    <Waypoint topOffset="20%" bottomOffset="20%" onEnter={onEnter}>
      <div
        className={`slice slice-cta text-${item.button_alignment} waypoint ${
          active ? "waypoint-active" : ""
        }`}
      >
        <a
          href={item.button_link.url}
          target={item.button_link.target ? item.button_link.target : "_self"}
          className="button button-large button-pink button-round"
          style={{ margin: "1.5rem 0" }}
        >
          {item.button_text.text}
        </a>
      </div>
    </Waypoint>
  );
};

const CaseStudy = ({ data, location }) => {
  const pageData = data.prismicCaseStudy.data;
  const slices = pageData.body;
  const { related_work_title, related_work } = data.prismicCaseStudy.data;

  const meta = {
    descripition: pageData.meta_description,
    title: pageData.meta_title,
    image: pageData.hero.fluid.src,
    uid: pageData.canonical.uid,
    pageType: pageData.canonical.type,
  };

  useEffect(() => {
    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.src = "https://www.tiktok.com/embed.js";
    head.appendChild(script);

    var head = document.getElementsByTagName("head")[0];
    var script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    head.appendChild(script);
  }, [pageData]);

  return (
    <Layout isDetail={true} location={location}>
      <SEO meta={meta} description={meta.descripition} title={meta.title} image={meta.image} uid={meta.uid} pageType={meta.pageType} />
      <div className="case-study page">
        <CaseStudyHero
          textColor={data.prismicCaseStudy.data.hero_text_color}
          content={data.prismicCaseStudy.data}
        />

        {slices.map((item, index) => {
          switch (item.__typename) {
            case "PrismicCaseStudyBodyTwoColumn":
              return <SplitContent content={item.primary} items={item.items} />;
            case "PrismicCaseStudyBodyContentBlock":
              return <FullContent content={item.primary} />;
            case "PrismicCaseStudyBodyQuoteBlock":
              return <QuoteSlider content={item.primary} />;
            case "PrismicCaseStudyBodyVideoBlock":
              return <VideoBlock content={item.primary} items={item.items} />;
            case "PrismicCaseStudyBodyVideoAnimation":
              return <VideoAnimation video={item.primary.video} />;
            case "PrismicCaseStudyBodyEmbedBlock":
              return <EmbedCode content={item.primary} />;
            case "PrismicCaseStudyBodyImageSliderBlock":
              return <Slider content={item.primary} items={item.items} />;
            case "PrismicCaseStudyBodyCallToAction":
              return (
                <div className="col col-md-10 col-md-offset-1">
                  <BlogCTA item={item.primary} />
                </div>
              );
            case "PrismicCaseStudyBodyLogoGridWExternalLinks":
              return (
                <LogoGridExternal content={item.primary} items={item.items} />
              );
            case "PrismicCaseStudyBodyClutchReviews":
              return <ClutchReviews />;
            default:
              // console.log(item.__typename)
              return null;
          }
        })}

        <div className="container">
          <Related
            label={related_work_title.text}
            items={related_work}
            type="case-study"
          />
        </div>
      </div>
    </Layout>
  );
};

CaseStudy.query = query;

export default withPreview(CaseStudy);
