import React from "react";
import "./CaseStudyContent.scss";
const CaseStudyContentFull = ({ children, theme, left, right }) => {
  return (
    <div
      className={`case-study-content content-full theme-${theme} align-center`}
    >
      <div className="row">
        <div className="col-md-10 col-md-offset-1">{children}</div>
      </div>
      {left && right && (
        <div className={`row`}>
          <div className="col-md-6 col case-study__rich-text">
            <div
              className="rich-column"
              dangerouslySetInnerHTML={{ __html: left }}
            />
          </div>
          <div className="col-md-6 col case-study__rich-text">
            <div
              className="rich-column"
              dangerouslySetInnerHTML={{ __html: right }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default CaseStudyContentFull;
